import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Inject, Output } from '@angular/core';

@Directive({
  selector: '[nodaScroll]',
  exportAs: 'scrollDirective',
  standalone: true,
})
export class ScrollDirective {
  public isScrolled: boolean = false;
  @Output()
  public scrollChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elRef: ElementRef,
  ) {}

  @HostListener('window:scroll', [])
  public onWindowScroll(): void {
    this.checkScroll();
  }

  public scrollToTop(): void {
    this.document.defaultView?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private checkScroll(): void {
    const elementTop: number = this.elRef.nativeElement.offsetTop;
    const scrollTop: number =
      this.document.documentElement.scrollTop || this.document.body.scrollTop;
    this.isScrolled = scrollTop > elementTop;
    this.scrollChange.emit(scrollTop);
  }
}
